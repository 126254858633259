<style scoped lang="less">
.top-item-row {
  margin: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.item-row {
  margin: 0.5rem;
  display: flex;
  white-space: nowrap;
  .el-input {
    width: 220px;
  }
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.content {
  flex: 1;
  overflow: auto;
}

.button-panel {
  width: 100%;
  padding: 10px 0;
  display: flex;
  box-shadow: 0 2px 2px #eee;
}
/deep/ .dialog-content-content {
  max-height: 50rem;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="标签编辑" v-show="isShow" @close="Close">
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
    </div>
    <div class="content">
      <div class="top-item-row">
        <div class="item-row">
          标题：
          <el-input v-model="title" placeholder="请输入内容"></el-input>
        </div>
        <div class="item-row">
          是否设置为数据库默认查询：
          <el-radio v-model="isDefault" label="1">是</el-radio>
          <el-radio v-model="isDefault" label="0">否</el-radio>
        </div>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../plugins/clone'

export default {
  name: 'SaveFieldsDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      title: '',
      isDefault: '0'
    }
  },
  methods: {
    Show() {
      this.$forceUpdate()
      this.isShow = true
    },
    Save() {
      if (!this.title) {
        return this.$message.warning('请填写标题')
      }
      this.$emit('save', this.title, this.isDefault)
      this.Close()
    },
    Close() {
      this.isShow = false
    }
  }
}
</script>
