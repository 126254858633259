<style scoped lang="less">
.top-item-row {
  margin: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.item-row {
  margin: 0.5rem 2rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.content {
  flex: 1;
  overflow: auto;
}

.button-panel {
  width: 100%;
  padding: 10px 0;
  display: flex;
  box-shadow: 0 2px 2px #eee;
}
/deep/ .dialog-content-content {
  max-height: 50rem;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="标签编辑" v-show="isShow" @close="Close">
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
    </div>
    <div class="content">
      <div class="top-item-row">
        <el-tree
          ref="defaultFieldsRef"
          :data="dialogFieldsList"
          node-key="id"
          show-checkbox
          default-expand-all
          empty-text="加载中，请稍候"
          :props="defaultProps"
          @check-change="handleCheckChange"
        ></el-tree>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../plugins/clone'

export default {
  name: 'AddFieldsListDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      defaultProps: {
        children: 'child',
        label: 'title'
      },
      dialogFieldsList: [],
      menusIds: []
    }
  },
  methods: {
    handleCheckChange(node, checked, indeterminate) {
      const { data, childNodes: children = [], id } = node

      // 检查 data 是否存在并且包含 id 属性
      if (!data || !data.id) {
        return
      }

      if (children.length > 0) return // 不是叶子节点直接返回

      const idIndex = this.menusIds.indexOf(id)

      if (checked && idIndex === -1) {
        this.menusIds.push(id)
      } else if (!checked && idIndex > -1) {
        this.menusIds.splice(idIndex, 1)
      }
    },

    Show(bloodVessel, list) {
      this.menusIds = list || []
      if (bloodVessel) this.dialogFieldsList = deepClone(bloodVessel)
      this.$forceUpdate()
      this.getTree()
      this.isShow = true
    },
    getTree() {
      if (this.menusIds.length > 0) {
        this.$refs.defaultFieldsRef.setCheckedKeys(this.menusIds)
      }
    },
    Save() {
      this.$emit('save', this.menusIds)
      this.Close()
    },
    Close() {
      this.isShow = false
    }
  }
}
</script>
